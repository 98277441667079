import { makeAutoObservable } from "mobx";
import { ProductInstanceTrackingId } from "../..";
import { randomUUID } from "../../id";
import { Availability } from "../Availability";
import { PreorderSettings } from "../PreorderSettings";
import { ProductConfig } from "../ProductConfig";
import { ProductInstancePart } from "../ProductInstancePart";
import { ProductInstanceHalves, ProductInstanceParts, SingleProductInstancePart, } from "../ProductInstanceParts";
/**
 * ProductInstance is collection of common config and config for parts
 * Common pittfall: Each product instance part can be of different product type, so we cannot say that ProductInstance is an instance of a ProductType
 * Can be split into parts.
 * Example: Large Margherita on thick dough with onion rings
 */
export class ProductInstance {
    constructor(params) {
        this.trackingId = params.trackingId;
        this.commonConfig = params.commonConfig;
        this.parts = params.parts;
        makeAutoObservable(this);
    }
    static fromStorageData({ menu, storageData, }) {
        var _a;
        const commonConfig = ProductConfig.fromStorageData({
            menu,
            modifierSettingsProvider: (modifierType) => modifierType.defaultSettings,
            storageData: storageData.commonConfig,
        });
        if (commonConfig === null) {
            return null;
        }
        const parts = ProductInstanceParts.fromStorageData({
            menu,
            storageData: storageData.parts,
        });
        if (parts === null) {
            return null;
        }
        const trackingId = (_a = storageData.trackingId) !== null && _a !== void 0 ? _a : new ProductInstanceTrackingId(randomUUID());
        return new ProductInstance({
            trackingId,
            commonConfig,
            parts,
        });
    }
    static fromSingleProductType(productType) {
        return new ProductInstance({
            trackingId: new ProductInstanceTrackingId(randomUUID()),
            commonConfig: ProductConfig.empty(),
            parts: new SingleProductInstancePart(ProductInstancePart.empty(productType)),
        });
    }
    static fromHalvesProductTypes(params) {
        const parts = ProductInstanceHalves.empty({
            productCategory: params.productCategory,
            first: ProductInstancePart.empty(params.firstProductType),
            second: ProductInstancePart.empty(params.secondProductType),
        });
        if (parts === null) {
            return null;
        }
        return new ProductInstance({
            trackingId: new ProductInstanceTrackingId(randomUUID()),
            commonConfig: ProductConfig.empty(),
            parts,
        });
    }
    eq(other) {
        return (this.commonConfig.eq(other.commonConfig) && this.parts.eq(other.parts));
    }
    /*
     * NOTICE This equivalence is not symmetrical
     * burger with size = large is cart equivalent to a burger with size = large and doneness = rare
     * but not vice versa
     */
    isCartEquivalentTo(other) {
        return (this.commonConfig.isCartEquivalentTo(other.commonConfig) &&
            this.parts.isCartEquivalentTo(other.parts));
    }
    get productCategoryId() {
        return this.parts.productCategoryId;
    }
    get productTypeIds() {
        return this.parts.productTypeIds;
    }
    get productWeeklySchedule() {
        return this.parts.productWeeklySchedule;
    }
    get customParameterChoiceIds() {
        const choiceIds = this.parts.customParameterChoiceIds;
        const common = this.commonConfig.customParameters.choiceIds;
        return choiceIds.union(common);
    }
    get availability() {
        return Availability.composite([
            Availability.dependent("CommonConfig", "", this.commonConfig.availability),
            Availability.dependent("ProductInstanceParts", "", this.parts.availability),
        ]);
    }
    get baseVolume() {
        return this.parts.baseVolume;
    }
    get configPackingItems() {
        return this.commonConfig.packingItems.unionWith(this.parts.configPackingItems, (a, b) => a.merge(b));
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        return {
            tag: "MenuProduct",
            contents: this.toCartProductKey(),
        };
    }
    /**
     * Convert the ProductInstance to `CartProductKey` representation, used in the rest of the ordering system.
     */
    toCartProductKey() {
        return {
            ...this.commonConfig.checkoutData,
            parts: this.parts.checkoutData,
        };
    }
    matchesCustomParameterValue(customParameterTypeId, customParameterChoiceId) {
        return (this.commonConfig.matchesCustomParameterValue(customParameterTypeId, customParameterChoiceId) ||
            this.parts.parts.some((part) => part.productConfig.matchesCustomParameterValue(customParameterTypeId, customParameterChoiceId)));
    }
    get storageData() {
        return {
            trackingId: this.trackingId,
            commonConfig: this.commonConfig.storageData,
            parts: this.parts.storageData,
        };
    }
    get preorderSettings() {
        const settings = this.parts.parts.map((part) => part.productType.preorderSettings);
        // NOTICE Both parts must have common dates
        return PreorderSettings.intersection(settings);
    }
}
