import { translate, t } from "../I18n";
export function createLabel() {
    return (labelParams) => {
        if (labelParams.fulfillmentTime === null) {
            return null;
        }
        const reasons = labelParams.reasons;
        if (reasons.includes("Delivery")) {
            return {
                message: translate(t.client.labels.not_available_fulfillment_method.delivery),
                enabledOnClick: false,
            };
        }
        else if (reasons.includes("Takeaway")) {
            return {
                message: translate(t.client.labels.not_available_fulfillment_method.takeway),
                enabledOnClick: false,
            };
        }
        else if (reasons.includes("DineIn")) {
            return {
                message: translate(t.client.labels.not_available_fulfillment_method.dinein),
                enabledOnClick: false,
            };
        }
        return null;
    };
}
