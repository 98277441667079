import { IdMap, RArray } from "../../collections";
import { makeImmutable } from "../../core";
import { PMenuSection } from "../../presentation";
export class PMenuSectionParser {
    constructor(params) {
        this.domainMenuSections = params.domainMenuSections;
        this.domainScheduleIds = params.domainScheduleIds;
        this.translatedParser = params.translatedParser;
        this.pMenuSectionItemParser = params.pMenuSectionItemParser;
        this.sectionVisibilityService = params.sectionVisibilityService;
        makeImmutable(this);
    }
    parse(id, section) {
        const domainSection = this.domainMenuSections.rawFind(id);
        if (!domainSection) {
            return null;
        }
        return new PMenuSection({
            domain: domainSection,
            name: this.translatedParser.parse(section.name),
            description: this.translatedParser.parse(section.description),
            // TODO: do we want to process it here (resize etc.)?
            photoUrn: section.photo,
            items: this.parseSectionItems(domainSection, section),
            visibilityScheduleId: section.visibilitySchedule === null
                ? null
                : this.domainScheduleIds.rawFind(section.visibilitySchedule),
            sectionVisibilityService: this.sectionVisibilityService,
        });
    }
    parseSectionItems(domainSection, section) {
        if (section.content.tag !== "Products") {
            return IdMap.empty();
        }
        const sectionItems = new RArray(section.content.items).mapOptional(([itemId, item]) => this.pMenuSectionItemParser.parse({
            domainSection,
            section,
            id: itemId,
            item,
        }));
        return IdMap.fromIterable(sectionItems);
    }
}
