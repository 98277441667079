import { EmptyFulfillmentFormReactions } from "./EmptyFulfillmentFormReactions";
import { OnlineFulfillmentFormReactions } from "./OnlineFulfillmentFormReactions";
export const FulfillmentFormReactions = {
    create(params) {
        switch (params.fulfillmentTime.type) {
            case "Online": {
                if (params.fulfillmentFormController.type !== "Online") {
                    throw new Error("PFulfillmentTime and FulfillmentFormController type mismatch");
                }
                return new OnlineFulfillmentFormReactions({
                    fulfillmentTime: params.fulfillmentTime,
                    fulfillmentFormController: params.fulfillmentFormController,
                });
            }
            default:
                return new EmptyFulfillmentFormReactions();
        }
    },
};
