import { makeAutoObservable } from "mobx";
import { RArray, RSet } from "../../collections";
import { Availability } from "../Availability";
import { ProductInstancePart, } from "../ProductInstancePart/ProductInstancePart";
/**
 * A product instance that is not split into halves.
 */
export class SingleProductInstancePart {
    constructor(part) {
        this.type = "SinglePart";
        this.isSplitPossible = true;
        this.part = part;
        makeAutoObservable(this);
    }
    static fromStorageData({ menu, storageData, }) {
        const part = ProductInstancePart.fromStorageData({
            menu,
            storageData: storageData.part,
        });
        if (part === null) {
            return null;
        }
        return new SingleProductInstancePart(part);
    }
    eq(other) {
        return other.type === "SinglePart" && this.part.eq(other.part);
    }
    isCartEquivalentTo(other) {
        return (other.type === "SinglePart" && this.part.isCartEquivalentTo(other.part));
    }
    get availability() {
        return Availability.dependent("DividedConfiguration", "", this.part.availability);
    }
    get productCategoryId() {
        return this.part.productCategoryId;
    }
    get productTypeIds() {
        return RSet.singleton(this.part.productTypeId);
    }
    get productWeeklySchedule() {
        return this.part.weeklySchedule;
    }
    get customParameterChoiceIds() {
        return this.part.customParameterChoiceIds;
    }
    get baseVolume() {
        const volume = this.part.baseVolume;
        return volume ? { productTypeIds: this.productTypeIds, volume } : null;
    }
    get configPackingItems() {
        return this.part.configPackingItems;
    }
    get parts() {
        return RArray.singleton(this.part);
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        return [this.part.checkoutData];
    }
    get productInstanceParts() {
        return [this.part];
    }
    get storageData() {
        return {
            type: "SinglePart",
            part: this.part.storageData,
        };
    }
}
