import { translate, t } from "../I18n";
export function createLabel() {
    return (labelParams) => {
        if (labelParams.fulfillmentTime === null) {
            return null;
        }
        const reasons = labelParams.reasons;
        const unavailable = reasons.includes("Schedule") || reasons.includes("Preorder");
        if (unavailable) {
            if (labelParams.now.compare(labelParams.fulfillmentTime) !== 0) {
                return {
                    message: translate(t.client.labels.not_available_now.preorder_text),
                    enabledOnClick: true,
                };
            }
            return {
                message: translate(t.client.labels.not_available_now.text),
                enabledOnClick: true,
            };
        }
        return null;
    };
}
