import { makeAutoObservable } from "mobx";
import { RArray } from "../../collections";
import { DateRange, DysjointDateRanges } from "../../core";
import { Availability } from "../Availability";
import { AvailableDates } from "../AvailableDates/AvailableDates";
import { findNextOrderingHour } from "../Availability/OrderingHoursAvailability";
export class OnlinePurchaseOrderFulfillmentTime {
    constructor(params) {
        this.type = "Online";
        this.orderingHours = params.orderingHours;
        this.payload = params.payload;
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.enableDelayedDelivery = params.enableDelayedDelivery;
        this.allowOrderingInClosedRestaurant =
            params.allowOrderingInClosedRestaurant;
        this.now = params.now;
        this.availableDates = new AvailableDates({
            orderingHours: params.orderingHours,
            now: params.now,
            payload: params.payload,
            allowOrderingInClosedRestaurant: params.allowOrderingInClosedRestaurant,
        });
        makeAutoObservable(this);
    }
    get fulfillmentTime() {
        return this.fulfillmentInstructions.fulfillmentTime;
    }
    set fulfillmentTime(value) {
        this.fulfillmentInstructions.fulfillmentTime = value;
    }
    get activeDateHours() {
        const date = this.fulfillmentTime !== null && this.fulfillmentTime.type === "OnTime"
            ? this.fulfillmentTime.date
            : null;
        if (date === null) {
            return RArray.empty();
        }
        return this.availableDates.availableDates.hoursForDate(date);
    }
    get preorderDateRanges() {
        return this.payload.preorderSettings.dateRanges(this.now);
    }
    get isPlacingOrderPossibleAsap() {
        const event = this.orderingHours.nextEventAtDate(this.now);
        switch (event.type) {
            // NOTICE Ordering is closed, but will begin at this date and restaurant allows placing orders outside ordering hours
            case "OrderingBegins":
                return (this.allowOrderingInClosedRestaurant &&
                    !this.enableDelayedDelivery &&
                    event.at !== null);
            // NOTICE Ordering is open and will end in the future
            case "OrderingEnds":
                return true;
        }
    }
    get isPlacingOrderPossible() {
        const event = this.orderingHours.nextEventAtDate(this.now);
        switch (event.type) {
            // NOTICE Ordering is closed, but will begin at this date and restaurant allows placing orders outside ordering hours
            case "OrderingBegins":
                return (this.allowOrderingInClosedRestaurant &&
                    findNextOrderingHour(this.now, this.orderingHours) !== null);
            // NOTICE Ordering is open and will end in the future
            case "OrderingEnds":
                return true;
        }
    }
    get asapOptionAvailable() {
        const endOfDay = this.orderingHours.endOfDay(this.now);
        if (endOfDay === null) {
            return false;
        }
        const todayRanges = DysjointDateRanges.singleton(DateRange.fromDates({
            begin: this.now,
            end: endOfDay,
        }));
        const hasTodayDate = !this.preorderDateRanges.intersection(todayRanges).isEmpty;
        return this.isPlacingOrderPossibleAsap && hasTodayDate;
    }
    get inFutureOptionAvailabile() {
        return (this.availableDates.hasFutureDates &&
            this.enableDelayedDelivery &&
            this.isPlacingOrderPossible);
    }
    get todayOptionAvailable() {
        const hasTodayDates = this.availableDates.todayHours.size > 0;
        return (hasTodayDates && this.enableDelayedDelivery && this.isPlacingOrderPossible);
    }
    // TODO: maybe we don't need this anymore?
    get availability() {
        const isAnyOptionAvailable = this.asapOptionAvailable ||
            this.todayOptionAvailable ||
            this.inFutureOptionAvailabile;
        // TODO: move it to ... (where)?
        // Used to display errors: PPUrchaseOrder.error
        return Availability.boolean({
            FulfillmentTimeOptionsMissing: !isAnyOptionAvailable,
            PreorderDatesEmpty: this.preorderDateRanges.isEmpty,
        });
    }
}
