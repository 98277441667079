import { PQrWaiterFulfillmentTime } from "./PQrWaiterFulfillmentTime";
import { POnlineFulfillmentTime } from "./POnlineFulfillmentTime";
import { translate, t } from "../../I18n";
export const PFulfillmentTime = {
    create(params) {
        if (params.purchaseOrderFulfillmentTime.type === "Online") {
            return new POnlineFulfillmentTime({
                timezone: params.timezone,
                purchaseOrderFulfillmentTime: params.purchaseOrderFulfillmentTime,
            });
        }
        else {
            return new PQrWaiterFulfillmentTime({
                fulfillmentInstructions: params.fulfillmentInstructions,
            });
        }
    },
};
function toLowerCase(str) {
    return str.toLowerCase();
}
export function getDayName(date) {
    const translated = date.isToday
        ? translate(t.date.today)
        : date.isTomorrow
            ? translate(t.date.tomorrow)
            : translate(t.date.day.names[toLowerCase(date.weekday)]);
    // NOTICE: Capitalize first letter due to translations missmatch (monday, Friday, etc.)
    return translated.charAt(0).toUpperCase() + translated.slice(1);
}
export function getAbbrMonthName(date) {
    return translate(t.date.month.abbr_names[toLowerCase(date.month)]);
}
