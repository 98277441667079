import { PaymentStorageSerializer } from "./PaymentStorageSerializer";
import { StorageManager } from "./StorageManager";
import { CartStorageSerializer } from "./CartStorageSerializer";
import { ApartmentInfoStorageSerializer } from "./ApartmentInfoStorageSerializer";
import { StreetAddressStorageSerializer } from "./StreetAddressStorageSerializer";
import { ConsentsStorageSerializer } from "./ConsentsStorageSerializer";
import { FulfillmentMethodTypeStorageSerializer } from "./FulfillmentMethodTypeStorageSerializer";
import { FulfillmentTimeStorageSerializer } from "./FulfillmentTimeStorageSerializer";
import { InvoiceDetailsStorageSerializer } from "./InvoiceDetailsStorageSerializer";
import { ContactDetailsStorageSerializer } from "./ContactDetailsStorageSerializer";
import { OrderItemListStorageSerializer } from "./OrderItemListStorageSerializer";
import { FieldTestsStorageSerializer } from "./FieldTestsStorageSerializer";
import { NumberStorageSerializer } from "./NumberStorageSerializer";
export class StorageManagerFactory {
    constructor(params) {
        this.storage = params.storage;
    }
    forApartmentInfo() {
        return new StorageManager({
            storage: this.storage,
            storageKey: "client_apartment_info",
            storageSerializer: new ApartmentInfoStorageSerializer(),
        });
    }
    forStreetAddress() {
        return new StorageManager({
            storage: this.storage,
            storageKey: "client_street_address",
            storageSerializer: new StreetAddressStorageSerializer(),
        });
    }
    // TODO: fixes
    forCart(restaurantSlug) {
        return new StorageManager({
            storage: this.storage,
            storageKey: `client_cart_${restaurantSlug}`,
            storageSerializer: new CartStorageSerializer(),
        });
    }
    forPreviousCart(restaurantSlug) {
        return new StorageManager({
            storage: this.storage,
            storageKey: `client_previousOrder_${restaurantSlug}`,
            storageSerializer: new CartStorageSerializer(),
        });
    }
    forConsents() {
        return new StorageManager({
            storage: this.storage,
            storageKey: `client_consents`,
            storageSerializer: new ConsentsStorageSerializer(),
        });
    }
    forFulfillmentMethodType() {
        return new StorageManager({
            storage: this.storage,
            storageKey: `client_fulfillmentMethod`,
            storageSerializer: new FulfillmentMethodTypeStorageSerializer(),
        });
    }
    forFulfillmentTime() {
        return new StorageManager({
            storage: this.storage,
            storageKey: `client_fulfillmentTime`,
            storageSerializer: new FulfillmentTimeStorageSerializer(),
        });
    }
    forInvoiceDetails() {
        return new StorageManager({
            storage: this.storage,
            storageKey: `client_invoiceDetails`,
            storageSerializer: new InvoiceDetailsStorageSerializer(),
        });
    }
    forOnlineContactDetails() {
        return new StorageManager({
            storage: this.storage,
            storageKey: `client_contactDetails`,
            storageSerializer: new ContactDetailsStorageSerializer(),
        });
    }
    forQrWaiterContactDetails() {
        return new StorageManager({
            storage: this.storage,
            storageKey: `client_contactDetails_qr_waiter`,
            storageSerializer: new ContactDetailsStorageSerializer(),
        });
    }
    forPayment() {
        return new StorageManager({
            storage: this.storage,
            storageKey: "client_paymentMedium",
            storageSerializer: new PaymentStorageSerializer(),
        });
    }
    forOrderItemList() {
        return new StorageManager({
            storage: this.storage,
            storageKey: "client_orderItemListTracking",
            storageSerializer: new OrderItemListStorageSerializer(),
        });
    }
    forFieldTests() {
        return new StorageManager({
            storage: this.storage,
            storageKey: "fieldTestExperiments",
            storageSerializer: new FieldTestsStorageSerializer(),
        });
    }
    forCurrentRestaurantId() {
        return new StorageManager({
            storage: this.storage,
            storageKey: "currentRestaurantId",
            storageSerializer: new NumberStorageSerializer(),
        });
    }
}
