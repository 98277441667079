import { makeAutoObservable } from "mobx";
import { RArray } from "../../collections";
import { DatesWithHours, } from "../PurchaseOrderFulfillmentTime/DatesWithHours";
export class AvailableDates {
    constructor(params) {
        this.orderingHours = params.orderingHours;
        this.now = params.now;
        this.payload = params.payload;
        this.allowOrderingInClosedRestaurant =
            params.allowOrderingInClosedRestaurant;
        makeAutoObservable(this);
    }
    get preorderDateRanges() {
        return this.payload.preorderSettings.dateRanges(this.now);
    }
    /**
     * Returns available days with hours.
     */
    get availableDates() {
        const availableDates = new RArray(this.preorderDateRanges.ranges).flatMap((preorderDateRange) => preorderDateRange.days.flatMap((date) => this.orderingHours
            .fulfillmentTimes({ now: this.now, date })
            .filtered((hour) => preorderDateRange.includes(hour))));
        if (this.allowOrderingInClosedRestaurant) {
            return DatesWithHours.create(availableDates);
        }
        else {
            return DatesWithHours.create(availableDates.filtered((date) => this.orderingHours.canOrderAt(date)));
        }
    }
    get todayHours() {
        return this.availableDates.hoursForDate(this.now);
    }
    get hasFutureDates() {
        return this.availableDates.totalHoursCount - this.todayHours.size > 0;
    }
}
