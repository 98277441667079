import { makeAutoObservable } from "mobx";
import { IdMap } from "../../collections";
import { Availability } from "../Availability";
import { PackingItem, ProductInstancePackingSubject } from "../PackingItem";
import { PositiveQuantity } from "../PositiveQuantity";
import { ProductInstance, } from "../ProductInstance";
import { Quantity } from "../Quantity";
/**
 * Line of an order. Tells what product and how many of that product the customer wants to buy.
 * Analogous to a line on a receipt, such as "Orange Juice, x2, $5.00"
 */
export class OrderLine {
    constructor(params) {
        this.productInstance = params.productInstance;
        this._quantity = params.quantity;
        makeAutoObservable(this);
    }
    static fromStorageData({ menu, storageData, }) {
        if (storageData.quantity < 1) {
            return null;
        }
        const productInstance = ProductInstance.fromStorageData({
            menu,
            storageData: storageData.productInstance,
        });
        if (productInstance === null) {
            return null;
        }
        return new OrderLine({
            productInstance,
            quantity: Quantity.fromNumber(storageData.quantity),
        });
    }
    mapProductInstance(mapping) {
        return new OrderLine({
            productInstance: mapping(this.productInstance),
            quantity: this.quantity,
        });
    }
    get quantity() {
        return this._quantity;
    }
    get isEmpty() {
        return this.quantity.isZero;
    }
    get productTypeIds() {
        return this.productInstance.productTypeIds;
    }
    get packingItems() {
        const quantity = new PositiveQuantity(this.quantity.value);
        const result = [
            ...this.productInstance.configPackingItems.objects.map((packingItem) => packingItem.multiply(quantity)).raw,
        ];
        const baseVolume = this.productInstance.baseVolume;
        if (baseVolume) {
            result.push(new PackingItem({
                subject: new ProductInstancePackingSubject(baseVolume),
                quantity,
            }));
        }
        return IdMap.fromIterable(result);
    }
    get availability() {
        const productTypesString = this.productTypeIds
            .rawValues()
            .map((productTypeId) => productTypeId.toString())
            .join(" & ");
        return Availability.dependent("ProductInstance", `Of types: ${productTypesString}`, this.productInstance.availability);
    }
    get preorderSettings() {
        return this.productInstance.preorderSettings;
    }
    get weeklySchedule() {
        return this.productInstance.parts.productWeeklySchedule;
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        return {
            productKey: this.productInstance.checkoutData,
            trackingId: this.productInstance.trackingId.value,
            quantity: this.quantity.value,
            note: null,
        };
    }
    increaseQuantity() {
        this._quantity = this.quantity.incremented();
    }
    addQuantity(quantity) {
        this._quantity = this.quantity.add(quantity);
    }
    decreaseQuantity() {
        if (this.quantity.value > 0) {
            this._quantity = this.quantity.decremented();
        }
    }
    get storageData() {
        return {
            productInstance: this.productInstance.storageData,
            quantity: this.quantity.value,
        };
    }
}
