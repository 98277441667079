import { makeAutoObservable } from "mobx";
import { PDescription, PProductUnavailabilityDetails, } from ".";
import { naturalCompare } from "../collections";
import { OrderLinePrice } from "../pricing/OrderLinePrice";
import { POrderLineId } from "./POrderLineId";
import { POrderLineProductType } from "./POrderLineProductType";
import { PPrice } from "./PPrice";
export class POrderLine {
    constructor(params) {
        this.id = new POrderLineId();
        this.domain = params.domain;
        this.restaurant = params.restaurant;
        this.localizationProvider = params.localizationProvider;
        makeAutoObservable(this);
    }
    get trackingId() {
        return this.domain.productInstance.trackingId;
    }
    get localizedPrice() {
        return new PPrice(this.localizationProvider, OrderLinePrice.totalPrice(this.domain).spectrum);
    }
    get price() {
        var _a;
        return (_a = this.localizedPrice.priceValue) !== null && _a !== void 0 ? _a : "0";
    }
    get unitPrice() {
        var _a, _b;
        return (_b = (_a = OrderLinePrice.unitPrice(this.domain).finalValue) === null || _a === void 0 ? void 0 : _a.asNumber) !== null && _b !== void 0 ? _b : null;
    }
    get quantity() {
        return this.domain.quantity.value;
    }
    get name() {
        return this.parts.map((part) => part.name).join(" / ");
    }
    get nameWithQuantity() {
        return this.quantity > 1
            ? `${this.domain.quantity.toString()}x ${this.name}`
            : this.name;
    }
    get description() {
        return PDescription.orderLine({
            productInstance: this.domain.productInstance,
            customParameterTypes: this.restaurant.customParameterTypes,
            modifierTypes: this.restaurant.modifierTypes,
            productTypes: this.restaurant.productTypes,
        });
    }
    get image() {
        var _a;
        return ((_a = this.parts
            .map((productType) => productType.photo)
            .find((photo) => photo !== null)) !== null && _a !== void 0 ? _a : null);
    }
    get weeklySchedule() {
        return this.domain.productInstance.productWeeklySchedule;
    }
    get availability() {
        return this.domain.productInstance.availability;
    }
    get error() {
        const { error } = new PProductUnavailabilityDetails({
            availability: this.availability,
            restaurant: this.restaurant,
        });
        return error;
    }
    get hasError() {
        return this.error !== null;
    }
    get parts() {
        return this.domain.productInstance.parts.productInstanceParts
            .map((productInstancePart) => {
            const productType = this.restaurant.productTypes.get(productInstancePart.productTypeId);
            const description = PDescription.productPart({
                productInstancePart,
                customParameterTypes: this.restaurant.customParameterTypes,
                modifierTypes: this.restaurant.modifierTypes,
                productTypes: this.restaurant.productTypes,
            });
            return new POrderLineProductType({ productType, description });
        })
            .sort((a, b) => naturalCompare(a.name, b.name));
    }
    get productTypeIds() {
        return this.domain.productInstance.productTypeIds;
    }
    get isSinglePart() {
        return this.domain.productInstance.parts.type === "SinglePart";
    }
    increaseQuantity() {
        this.domain.increaseQuantity();
    }
    decreaseQuantity() {
        this.domain.decreaseQuantity();
    }
}
