import { makeAutoObservable } from "mobx";
import { ASAPFulfillmentTime, Availability, } from "../../ordering";
import { translate, t } from "../../I18n";
export class PAsapFulfillmentTime {
    constructor(params) {
        this.type = "ASAP";
        this.purchaseOrderFulfillmentTime = params.purchaseOrderFulfillmentTime;
        this.label = translate(t.orders.form.asap);
        makeAutoObservable(this);
    }
    get isAvailable() {
        return this.purchaseOrderFulfillmentTime.asapOptionAvailable;
    }
    get domainValue() {
        return new ASAPFulfillmentTime();
    }
    get availability() {
        return Availability.available();
    }
}
