import { Availability, } from "../../ordering";
export class PQrWaiterFulfillmentTime {
    constructor(params) {
        this.type = "QrWaiter";
        this.optionValue = "ASAP";
        this.isEditable = false;
        this.fulfillmentInstructions = params.fulfillmentInstructions;
    }
    get domain() {
        return this.fulfillmentInstructions.fulfillmentTime;
    }
    get availability() {
        return Availability.available();
    }
}
