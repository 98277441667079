import { translate, t } from "../I18n";
export function createLabel() {
    return (labelParams) => {
        const reasons = labelParams.reasons;
        const unavailable = reasons.includes("TemporarilyUnavailable");
        if (unavailable) {
            return {
                message: translate(t.client.labels.temporarily_unavailable.text),
                enabledOnClick: false,
            };
        }
        return null;
    };
}
