/**
 * Empty implementation of FulfillmentFormReactions for QrWaiter
 */
export class EmptyFulfillmentFormReactions {
    initialize() {
        // NOTICE: Intentionally empty;
    }
    dispose() {
        // NOTICE: Intentionally empty;
    }
}
