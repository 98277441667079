import { DateRange, DysjointDateRanges, makeImmutable, } from "../../core";
export class ClampFuture {
    constructor(maxDays) {
        this.type = "ClampFuture";
        this.isSameDayOnly = true;
        this.maxDays = maxDays;
        makeImmutable(this);
    }
    dateRanges(now) {
        const range = DateRange.fromDates({
            begin: now,
            end: now.add(this.maxDays, "days").endOfDay(),
        });
        return DysjointDateRanges.singleton(range);
    }
}
